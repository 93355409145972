<template>
  <div class="container bg1">
    <!-- 我要入园 -->
    <section class="section mb20">
      <div class="wrapper">
        <div class="section_title">我要入园</div>
        <div class="section_content" :style="{ height: sectionShow1 ? '358px' : '100px' }">
          <template v-if="sectionShow1">
            <div class="unfold-box">
              <div class="fl-c-b mb30">
                <div class="card-item" @click="go('EnterprisesInPark')">
                  <img src="../../assets/img/EnterpriseService/icon_1.png" alt="" class="card-img" />
                  <div class="card-title">入园须知</div>
                </div>
                <div class="card-item" @click="go('ProjectList')">
                  <img src="../../assets/img/EnterpriseService/icon_2.png" alt="" class="card-img" />
                  <div class="card-title">项目清单</div>
                </div>
                <!-- <div class="card-item" @click="go('MaterialManagement')">
                  <img src="../../assets/img/EnterpriseService/icon_4.png" alt="" class="card-img" />
                  <div class="card-title">材料管理</div>
                </div> -->
                <!-- <div class="card-item" @click="go('ConstructionManagement')"> -->
                <div class="card-item" @click="go()">
                  <img src="../../assets/img/EnterpriseService/icon_7.png" alt="" class="card-img" />
                  <div class="card-title">施工管理</div>
                </div>
              </div>
              <!-- <div class="fl-c-b">
                <div></div>
                <el-button plain class="pack-up-btn" @click="sectionShow1 = false">收起<i class="el-icon-arrow-up el-icon--right"></i></el-button>
              </div> -->
            </div>
          </template>
          <template v-else>
            <div class="pack-up-box fl-c-b">
              <div class="fl-c">
                <div class="pack-up-item">入园申请</div>
                <div class="pack-up-item">进度追踪</div>
                <div class="pack-up-item">材料管理</div>
                <div class="pack-up-item">施工管理</div>
              </div>
              <el-button plain class="pack-up-btn" @click="sectionShow1 = true">展开<i class="el-icon-arrow-down el-icon--right"></i></el-button>
            </div>
          </template>
        </div>
      </div>
    </section>
    <section>
      <div class="wrapper">
        <div class="section_title">企业办事</div>
        <div class="section_content" :style="{ height: sectionShow2 ? 'auto' : '100px' }">
          <template v-if="sectionShow2">
            <div class="unfold-box">
              <div class="box-title" style="margin-bottom: 26px">智脑决策</div>
              <div class="tag mb20">企业档案</div>
              <div class="fl-box mb30">
                <div class="decision-item archives" @click="go('EADetails')">
                  <div class="decision-title">企业档案馆</div>
                </div>
                <div class="decision-item archives" @click="open('https://esso.zjzwfw.gov.cn/opensso/spsaehandler/metaAlias/sp?spappurl=https://qy.ojkcsdn.com/enterprise-app?goto=https://qy.ojkcsdn.com/pc/')">
                  <div class="decision-title">企业画像</div>
                </div>
                <!-- <div class="decision-item" @click="go('IntelligentDiagnosis')">
                  <div class="decision-title">智能诊断</div>
                  <div class="fl-c">
                    <div>业务办理</div>
                    <div class="decision-img"></div>
                  </div>
                </div> -->
              </div>
              <div class="tag">智能诊断</div>
              <div class="fl-box mb30">
                <!-- <div class="decision-item diagnosis" @click="go('IntelligentDiagnosis')">
                  <div class="decision-title">企业评估</div>
                </div> -->
                <div class="card-item" @click="go()">
                <!-- <div class="card-item" @click="go('IntelligentDiagnosis')"> -->
                  <div class="diagnosis fl-c-c">
                    <img src="../../assets/img/EnterpriseService/qypg_img.png" alt="" />
                  </div>
                  <div class="card-title">企业评估</div>
                </div>
                <!-- <div class="card-item" @click="go('IndustryEvaluation')"> -->
                <div class="card-item" @click="go('IndustryEvaluation')">
                  <div class="diagnosis fl-c-c">
                    <img src="../../assets/img/EnterpriseService/cypg_img.png" alt="" />
                  </div>
                  <div class="card-title">产业评估</div>
                </div>
                <div class="card-item" @click="go()">
                <!-- <div class="card-item" @click="go('operating')"> -->
                  <div class="diagnosis fl-column-c">
                    <img src="../../assets/img/EnterpriseService/img_10.png" alt="" />
                  </div>
                  <div class="card-title">经营分析</div>
                </div>
                <div class="card-item" @click="go()">
                <!-- <div class="card-item" @click="go('warning')"> -->
                  <div class="diagnosis fl-c-c">
                    <img src="../../assets/img/EnterpriseService/img_5.png" alt="" />
                  </div>
                  <div class="card-title">风控预警</div>
                </div>
                <!-- <div class="decision-item diagnosis" @click="go('IndustryEvaluation')">
                  <div class="decision-title">产业评估</div>
                </div>
                <div class="decision-item diagnosis" @click="go('warning')">
                  <div class="decision-title">风控预警</div>
                </div> -->
              </div>
              <div class="box-title mb20">政府办事</div>
              <div class="fl-box mb30">
                <div class="policy mr20">
                  <div class="tag mb20">政策服务</div>
                  <div class="policy-item policy-border" @click="go('GovServicePolicy',null,{socialCode})">
                    <img src="../../assets/img/EnterpriseService/icon_9.png" alt="" class="policy-img" />
                    <div class="policy-title">政策兑付</div>
                    <div class="fl-c-c">
                      <div>业务办理</div>
                      <div class="policy-icon"></div>
                    </div>
                  </div>
                </div>
                <div class="fl-1">
                  <div class="tag mb20">审批服务</div>
                  <div class="fl-c-b">
                    <div class="list">
                      <img src="../../assets/img/EnterpriseService/zmain_icon_prepare_normal.png.png" alt="" class="list-icon" />
                      <div class="list-title">创业准备</div>
                      <div class="list-body">
                        <a class="list-item" href="http://223.4.69.207:8082/public" target="open">浙江省“标准地”数字地图</a>
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=3c6066ad-9129-419c-b067-81e72b304ed4&jurisCode=330000" target="open">占用林地许可</a>
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=46116ffa-05b6-416c-b9ef-27d8e88e466c" target="open">民办非企业单位成立登记</a>
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?impleCode=ff8080815d551320015d58a5a2f200222330800045002" target="open">技术先进型服务企业认定</a>
                      </div>
                    </div>
                    <div class="list">
                      <img src="../../assets/img/EnterpriseService/zmain_icon_register_normal.png.png" alt="" class="list-icon" />
                      <div class="list-title">企业登记</div>
                      <div class="list-body">
                        <a class="list-item" href="http://gswsdj.zjzwfw.gov.cn/entrance.html" target="open">公司设立登记</a>
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=293335f0-74e0-4394-9a27-e2f26657841b&jurisCode=330000" target="open">公司变更登记</a>
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=da453f4d-68f7-4cdf-aad1-e6948ee72f70&jurisCode=330000" target="open">发票领用</a>
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=46116ffa-05b6-416c-b9ef-27d8e88e466c" target="open">民办非企业单位成立登记</a>
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=46116ffa-05b6-416c-b9ef-27d8e88e466c" target="open">民办非企业单位成立登记</a>
                      </div>
                    </div>
                    <div class="list">
                      <img src="../../assets/img/EnterpriseService/zmain_icon_management_normal.png.png" alt="" class="list-icon" />
                      <div class="list-title">企业管理</div>
                      <div class="list-body">
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=c7124f95-15dc-4ce9-8c9f-e3298c654e04&jurisCode=330000" target="open">执行合同</a>
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=66eb9aa8-34cc-446b-ba34-7ba636a7bd3c&jurisCode=330000" target="open">办理破产</a>
                        <a class="list-item" href="http://xyxx.zjzwfw.gov.cn/index/?webId=1#/index/searchHome" target="open">公共信用服务</a>
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?impleCode=ff8080815d551320015d58a5a2f200222330800045002" target="open">技术先进型服务企业认定</a>
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?impleCode=ff8080815d551320015d58db4fdc00382331620019000" target="open">纳税一件事联办 </a>
                      </div>
                    </div>
                    <div class="list">
                      <img src="../../assets/img/EnterpriseService/zmain_icon_production_normal.png.png" alt="" class="list-icon" />
                      <div class="list-title">生产经营</div>
                      <div class="list-body">
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?impleCode=ff8080815d551320015d58db4fdc00382330800340030" target="open">一照一码户信息变更</a>
                        <a class="list-item" href="https://loanpc.zjfcs.org.cn/?1=1&applyPlatform=1" target="open">浙里网上贷</a>
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=4498b905-8e66-46de-8383-81d3d25722dc&jurisCode=330000" target="open">完税证明开具</a>
                        <!-- <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=3b58dd57-98af-444c-84ee-2c16ad27f7d8&jurisCode=330000" target="open">代扣代缴报告</a> -->
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=34d2cdc8-4ba7-45ba-81b5-df2cee335438&jurisCode=330000" target="open">存款账户账号报告</a>
                      </div>
                    </div>
                    <div class="list">
                      <img src="../../assets/img/EnterpriseService/zmain_icon_logout_normal.png.png" alt="" class="list-icon" />
                      <div class="list-title">企业注销</div>
                      <div class="list-body">
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=8a262847-48da-4dca-9ed4-633d81d19554&jurisCode=330000" target="open">公司注销登记</a>
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=dfa41378-f587-41d0-bd5b-46e364b3563c&jurisCode=330000" target="open">分公司注销登记</a>
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=f7d3cba6-ba1f-44f1-ae9b-4ede6a412cf6&jurisCode=330000" target="open">外商投资企业登记（注销）</a>
                        <a class="list-item" href="http://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=19d47801-d892-43d3-af8b-2abcd41ad82f&jurisCode=330000" target="open">外商投资企业分支机构登记（注销）</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tag mb20">政务服务</div>
              <div class="fl-wrap mb30">
                <!-- <div class="work-item mb20" @click="go('EnterpriseReport')">
                  <div class="work-title">企业预报</div>
                  <div class="fl-c">
                    <div>业务办理</div>
                    <div class="work-img"></div>
                  </div>
                </div> -->
                <div class="work-item znxz" @click="go('SmartLocation')">
                  <div class="work-title">智能选址</div>
                  <div class="fl-c">
                    <div>业务办理</div>
                    <div class="work-img"></div>
                  </div>
                </div>
                <div class="work-item ygzn" @click="go('entrance')">
                  <div class="work-title">员工子女入学</div>
                  <div class="fl-c">
                    <div>业务办理</div>
                    <div class="work-img"></div>
                  </div>
                </div>
                <div class="work-item gsrd" @click="go('workInjury')">
                  <div class="work-title">工伤认定申报</div>
                  <div class="fl-c">
                    <div>业务办理</div>
                    <div class="work-img"></div>
                  </div>
                </div>
                <div class="work-item qyyg" @click="go('StaffNeedList')">
                  <div class="work-title">企业用工需求</div>
                  <div class="fl-c">
                    <div>业务办理</div>
                    <div class="work-img"></div>
                  </div>
                </div>
                <div class="work-item pxhd" @click="go('ActivitiesSquare')">
                  <div class="work-title">培训活动</div>
                  <div class="fl-c">
                    <div>业务办理</div>
                    <div class="work-img"></div>
                  </div>
                </div>
                
              </div>
              <div class="box-title mb20">应用超市</div>
              <div class="mb30">
                <div class="tag mb20">业务发展</div>
                <div class="fl-box mb30">
                  <div class="market-item" @click="go()">
                  <!-- <div class="market-item" @click="go('customersRecommend')"> -->
                    <img src="../../assets/img/EnterpriseService/img_kyzt@2x.png" alt="" class="market-img" />
                    <div>客源智推</div>
                  </div>
                  <div class="market-item" @click="go()">
                  <!-- <div class="market-item" @click="go('SupplyandDemand')"> -->
                    <img src="../../assets/img/EnterpriseService/img_hygq@2x.png" alt="" class="market-img" />
                    <div>行业供求市场</div>
                  </div>
                  <div class="market-item" @click="go()">
                  <!-- <div class="market-item" @click="go('ServiceProviders')"> -->
                    <img src="../../assets/img/EnterpriseService/sytj_img_normal@2x.png" alt="" class="market-img" />
                    <div>服务商推荐</div>
                  </div>
                  <div class="market-item" style="margin-right: 0" @click="go()">
                  <!-- <div class="market-item" style="margin-right: 0" @click="go('DigitalTransform')"> -->
                    <img src="../../assets/img/EnterpriseService/img_szhgz@2x.png" alt="" class="market-img" />
                    <div>数字化改造推荐</div>
                  </div>
                </div>
                <div class="tag mb20">资金扶持</div>
                <div class="fl-box">
                  <div class="market-item" @click="go('FinancialMarket')">
                    <img src="../../assets/img/EnterpriseService/img_jrcs@2x.png" alt="" class="market-img" />
                    <div>金融超市</div>
                  </div>
                  <div class="market-item" @click="go('IndustrialFund')">
                    <img src="../../assets/img/EnterpriseService/img_cyjj@2x.png" alt="" class="market-img" />
                    <div>产业基金</div>
                  </div>
                  <div class="market-item" @click="handleOpenBISystem">
                    <img src="../../assets/img/EnterpriseService/sytj_img_normal@2x.png" alt="" class="market-img" />
                    <div>BI分析</div>
                  </div>
                </div>
              </div>
              <!-- <div class="fl-c-b">
                <div></div>
                <el-button plain class="pack-up-btn" @click="sectionShow2 = false">收起<i class="el-icon-arrow-up el-icon--right"></i></el-button>
              </div> -->
            </div>
          </template>
          <template v-else>
            <div class="pack-up-box fl-c-b">
              <div class="fl-c">
                <div class="pack-up-item">智脑决策</div>
                <div class="pack-up-item">政府办事</div>
                <div class="pack-up-item">应用超市</div>
              </div>
              <el-button plain class="pack-up-btn" @click="sectionShow2 = true">展开<i class="el-icon-arrow-down el-icon--right"></i></el-button>
            </div>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: 'enterprisesInPark',
  data() {
    return {
      sectionShow1: true,
      sectionShow2: true,
    };
  },
  computed:{
   ...mapGetters(["socialCode"])
  },
  methods: {
    handleOpenBISystem(){
      const appKey = 'test3100'
      const uid =this.socialCode
      const t = new Date().getTime();
      const token = md5(`${appKey}|${t}|${uid}`)
      const url = `https://zf.txtscn.com:8088/chart?token=${token}&uid=${uid}&t=${t}`
      window.open(url)
    },
    open(url) {
      window.open(url);
    },
    go(name, id,query ={}) {
      if(!name){
        this.$message.warning("开发中...");
        return;
      }
      if (id) {
        this.$router.push({ name, params: { id } });
      } else {
        this.$router.push({ name ,query});
      }
    },
    application() {
      // window.vBus.$emit('apply');
    },
    scrollTo() {
      let arr = ['EnterprisesInPark', 'AdminService', 'IndustrialService'];
      if (arr.includes(this.$route.name)) {
        var id = '#' + this.$route.name;
        // 利用scrollIntoView这个api实现自动跳转
        document.querySelector(id).scrollIntoView(true);
        window.scroll(0, window.scrollY - 60);
      }
    },
  },
  mounted() {
    this.scrollTo();
  },
  created() {
    // this.$api.getactivityList().then((res) => {
    //   this.list = res.rows;
    // });
    // this.$api.getActivitySchedule().then((res) => {
    //   this.info = res.result;
    // });
  },
  watch: {
    $route() {
      this.scrollTo();
    },
  },
};
</script>

<style lang='scss' scoped>
* {
  box-sizing: border-box;
}
.wrapper {
  padding-bottom: 0;
}
.bg1 {
  background: url(../../assets/img/img_qybs_top.jpg) no-repeat;
  background-position: center 60px;
  background-size: 100% 226px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mr20 {
  margin-right: 20px;
}

.container {
  padding-bottom: 60px;
  font-family: MicrosoftYaHei;
}

.section_title {
  font-size: 20px;
  color: #333333;
  font-weight: 600;
  text-indent: 36px;
  padding-top: 15px;
  line-height: 36px;
  height: 76px;
  background: url(../../assets/img/EnterpriseService/img_top_bg.png) no-repeat;
  background-size: 100%;
}
.section_content {
  background: #ffffff;
  border-radius: 16px;
  margin-top: -20px;
  overflow: hidden;
  transition: all 0.3s;
  .unfold-box {
    padding: 30px 20px;

    .card-item {
      width: calc((100% - 60px) / 4);
      margin-top: 20px;
      cursor: pointer;
      font-size: 18px;
      color: #fff;
      font-weight: 500;
      text-align: center;
      margin-right: 20px;
      box-shadow: 0 2px 12px 0 rgba(97, 143, 221, 0.2);
      border-radius: 4px;
      .card-img {
        width: 128px;
        height: 128px;
        display: block;
        margin: 10px auto;
      }
      .card-title {
        background: #5282f0;
        opacity: 0.85;
        border-radius: 0 0 4px 4px;
        line-height: 60px;
        height: 60px;
      }
      &:hover .card-title {
        opacity: 1;
      }
      &:nth-last-of-type(1) {
        margin-right: 0;
      }
    }
    .box-title {
      font-size: 20px;
      color: #333333;
      text-align: center;
      line-height: 36px;
      font-weight: 600;
      width: 104px;
      text-align: center;
      position: relative;
      z-index: 1;
      &::before {
        position: absolute;
        content: '';
        bottom: 1px;
        left: 0;
        height: 9px;
        width: 100%;
        background-color: #cadfff;
        z-index: -1;
      }
    }
    .decision-item {
      width: calc((100% - 38px) / 3);
      margin-right: 19px;
      height: 180px;
      box-shadow: 0 2px 12px 0 rgba(97, 143, 221, 0.2);
      border-radius: 4px;
      transition: all 0.3s;
      padding-left: 30px;
      font-size: 16px;
      color: #666666;
      line-height: 180px;
      cursor: pointer;
      &:hover {
        border: 1px solid #135694;
        box-shadow: 0 6px 10px 0 rgba(19, 86, 148, 0.2);
        color: #0e459c;
        transform: translateY(-16px);
      }
      &:hover .decision-title {
        color: #0e459c;
      }
      &:hover .decision-img {
        background: url(../../assets/img/EnterpriseService/icon_qw_s.png) center no-repeat;
      }
      .decision-title {
        font-size: 18px;
        color: #333333;
        font-weight: 600;
      }
      .decision-img {
        width: 16px;
        height: 16px;
        margin-left: 8px;
        background: url(../../assets/img/EnterpriseService/icon_qw_n.png) center no-repeat;
      }
    }
    .archives {
      &:nth-of-type(1) {
        background: url(../../assets/img/EnterpriseService/img_1.png) bottom right no-repeat;
      }
      &:nth-of-type(2) {
        background: url(../../assets/img/EnterpriseService/img_2.png) bottom right no-repeat;
      }
    }
    .diagnosis {
      height: 190px;
    }
    .tag {
      font-size: 14px;
      color: #0e459c;
      width: 96px;
      height: 36px;
      line-height: 36px;
      padding-left: 16px;
      background: url(../../assets/img/EnterpriseService/img_bq_bg.png) no-repeat;
    }
    .policy {
      .policy-border {
        box-shadow: 0 2px 12px 0 rgba(97, 143, 221, 0.2);
        border-radius: 4px;
      }
      .policy-item {
        height: 215px;
        padding-top: 21px;
        font-size: 16px;
        color: #666666;
        text-align: center;
        cursor: pointer;
        .policy-img {
          display: block;
          width: 68px;
          height: 68px;
          margin: 0 auto;
        }
        .policy-title {
          font-size: 18px;
          color: #333333;
          line-height: 24px;
          font-weight: 600;
          margin-top: 15px;
          margin-bottom: 8px;
        }
        .policy-icon {
          width: 16px;
          height: 16px;
          margin-left: 8px;
          background: url(../../assets/img/EnterpriseService/icon_qw_n.png) center no-repeat;
        }
        &:hover {
          color: #0e459c;
        }
        &:hover .policy-title {
          color: #0e459c;
        }
        &:hover .policy-icon {
          background: url(../../assets/img/EnterpriseService/icon_qw_s.png) center no-repeat;
        }
      }
      min-width: calc((100% - 60px) / 4);
    }
    .list {
      width: calc((100% - 56px) / 5);
      height: 215px;
      margin-right: 14px;
      border-radius: 2px;
      cursor: pointer;
      .list-icon {
        width: 24px;
        height: 24px;
        display: block;
        margin: 8px auto;
      }
      .list-title {
        font-size: 18px;
        color: #333333;
        margin-bottom: 12px;
        font-weight: 600;
        text-align: center;
        line-height: 18px;
      }
      .list-body {
        height: 145px;
        padding: 20px 27px 20px 15px;
        min-width: 0;
        .list-item {
          text-decoration: none;
          display:block;
          text-indent: 14px;
          font-size: 13px;
          color: #666666;
          margin-bottom: 10px;
          line-height: 14px;
          position: relative;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          &:hover{
            color: #333;
          }
          &::after {
            content: '';
            position: absolute;
            top: 4px;
            left: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #5282f0;
          }
        }
      }
      &:hover {
        box-shadow: 2px 2px 6px 0 rgba(4, 12, 58, 0.2);
      }
      &.znxz{
        background: #f8f8fa url(../../assets/img/EnterpriseService/bg01_normal.png.png) top center no-repeat;
        background-size: 100% 70px;
      }
      &.ygzn{
        background: #f8f8fa url(../../assets/img/EnterpriseService/bg02_normal.png.png) top center no-repeat;
        background-size: 100% 70px;
      }
      &.gsrd{
        background: #f8f8fa url(../../assets/img/EnterpriseService/bg03_normal.png.png) top center no-repeat;
        background-size: 100% 70px;
      }
      &.qyyg{
        background: #f8f8fa url(../../assets/img/EnterpriseService/bg04_normal.png.png) top center no-repeat;
        background-size: 100% 70px;
      }
      &.pxhd {
        background: #f8f8fa url(../../assets/img/EnterpriseService/bg05_normal.png.png) top center no-repeat;
        background-size: 100% 70px;
        margin-right: 0;
      }
    }
    .work-item {
      width: calc((100% - 57px) / 4);
      margin-right: 19px;
      height: 166px;
      box-shadow: 0 2px 12px 0 rgba(97, 143, 221, 0.2);
      border-radius: 4px;
      padding-left: 30px;
      padding-top: 52px;
      transition: all 0.3s;
      font-size: 16px;
      color: #666666;
      line-height: 21px;
      cursor: pointer;
      // &:nth-of-type(1) {
      //   background: url(../../assets/img/EnterpriseService/img_6.png) center right no-repeat;
      // }
      &:nth-of-type(1) {
        background: url(../../assets/img/EnterpriseService/znxz_img_content.png) center right no-repeat;
      }
      &:nth-of-type(2) {
        background: url(../../assets/img/EnterpriseService/img_7.png) center right no-repeat;
      }
      &:nth-of-type(3) {
        background: url(../../assets/img/EnterpriseService/img_9.png) center right no-repeat;
      }
      &:nth-of-type(4) {
        margin-right: 0;
        background: url(../../assets/img/EnterpriseService/img_8.png) center right no-repeat;
      }
      &:nth-of-type(5) {
        margin-top: 20px;
        background: url(../../assets/img/EnterpriseService/img_9.png) center right no-repeat;
      }
      &:hover {
        border: 1px solid #135694;
        box-shadow: 0 6px 10px 0 rgba(19, 86, 148, 0.2);
        color: #0e459c;
        transform: translateY(-10px);
      }
      &:hover .work-title {
        color: #0e459c;
      }
      &:hover .work-img {
        background: url(../../assets/img/EnterpriseService/icon_qw_s.png) center no-repeat;
      }
      .work-title {
        font-size: 18px;
        color: #333333;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 16px;
      }
      .work-img {
        width: 16px;
        height: 16px;
        margin-left: 8px;
        background: url(../../assets/img/EnterpriseService/icon_qw_n.png) center no-repeat;
      }
    }
    .market-item {
      width: calc((100% - 66px) / 4);
      margin-right: 22px;
      background: #5282f0;
      border-radius: 4px;
      // height: 300px;
      padding-top: 20px;
      padding-bottom: 23px;
      font-size: 18px;
      color: #fff;
      transition: all 0.3s;
      font-weight: 600;
      text-align: center;
      cursor: pointer;
      opacity: 0.85;
      .market-img {
        display: block;
        width: calc(100% - 42px);
        // height: 207px;
        margin: 0 21px 26px;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
.pack-up-box {
  height: 100px;
  width: 100%;
  .pack-up-btn {
    margin-right: 20px;
  }
  .pack-up-item {
    font-size: 20px;
    color: #333333;
    text-align: center;
    line-height: 36px;
    width: 153px;
    margin-right: 90px;
  }
  .pack-up-item:nth-of-type(1) {
    margin-right: 96px;
  }
  .pack-up-item:nth-last-of-type(1) {
    margin-right: 0;
  }
}
.section {
  padding-top: 113px;
}
</style>
